<template>
  <v-card class="mb-3 cursor-default" :hover="!question.isSeparator" :class="{'primary white--text': question.isSeparator}">
    <v-card-title class="pt-3 pb-1" v-text="(index + 1) + '. ' + question.title" />
    <v-card-text class="pb-0">
      <template v-if="question.countryRestriction">
        <v-avatar v-for="country in countryRestriction" :key="country.countryCode" size="24" class="mr-3 mb-3">
          <img
              :src="`${$publicPath}images/countries/${country.countryCode.toLowerCase()}.svg`"
              :alt="country.countryName"
          >
        </v-avatar><br />
      </template>
      <span :class="{'white--text': question.isSeparator}">{{ question.description }}</span>
    </v-card-text>
    <v-card-actions class="px-3 pt-3 pb-0">
        <v-radio-group
          v-if="!question.isSeparator"
          v-model="selectedAnswer"
          :disabled="!canEdit"
          row
          class="mt-0">
            <v-radio
              :label="$t('yes')"
              :value="1"
            ></v-radio>
            <v-radio
                :label="$t('no')"
                :value="0"
            ></v-radio>
        </v-radio-group>
    </v-card-actions>
  </v-card>
</template>

<script>
import { v4 as uuid } from 'uuid';

export default {
  name: 'VvtRiskBlacklistPanelQuestionnaireQuestion',
  props: {
    question: {
      type: Object,
      default: () => ({
          id: null,
          title: null,
          description: null,
          isSeparator: false,
          countryRestriction: null
      }),
    },
    answerId: {
      type: [Number, String],
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    answer: {
      type: Number,
      default: null
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    countryRestriction () {
      const arrCountries = this.question.countryRestriction.split(',');
      return arrCountries.map(x => {
        const country = x.split('_');
        return {
          countryCode: country[0],
          countryName: country[1]
        }
      })
    },
    selectedAnswer: {
      get () {
        return this.answer;
      },
      set (val) {
        this.$emit('answer', {
          id: this.answerId ? this.answerId : null,
          clientId: this.answerId ? null : uuid(),
          question: this.question,
          answer: val
        });
      }
    }
  }
}
</script>