<template>
  <v-card-text class="pa-0 ma-0">
    <v-card flat tile color="transparent" :loading="$wait.is('fetch questionnaire') ? 'primary' : false">
      <v-card-text>
        <div v-for="(n, index) in questionnaireQuestions" :key="n.id">
            <Question :question="n" :index="index" :canEdit="canEdit" :answer="getAnswerValue(n.id)" :answerId="getExistingAnswerId(n.id)" @answer="answerQuestion($event)" />
        </div>
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Question from './Question.vue';

export default {
  name: 'VvtRiskBlacklistPanelQuestionnaire',
    components: {
        Question
    },
  props: {
    disabled: {
        type: Boolean,
        default: false,
    },
    countryCode: {
        type: String,
        default: null,
    },
    questionnaireQuestions: {
      type: Array,
      default: () => [],
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
        getAnswer: 'processingActivityModel/getBlacklistAnswer'
    })
  },
  methods: {
    ...mapActions({
        setAnswer: 'processingActivityModel/setBlacklistAnswer'
    }),
    answerQuestion (val) {
      this.setAnswer({
          id: val.id,
          clientId: val.clientId,
          blacklistQuestionId: val.question.id,
          answer: val.answer
      });
    },
    getAnswerValue (val) {
      const answer = this.getAnswer(val);
      return answer ? answer.answer : null;
    },
    getExistingAnswerId (val) {
      const answer = this.getAnswer(val);
      return answer ? answer.id : null;
    }
  }
}
</script>