<template>
    <v-card flat tile color="secondary">
        <BlacklistQuestionnaire v-if="getCompanyGroupSettings('blacklistQuestionnaire') && !template" :disabled="disabled" :canEdit="canEdit" :countryCode="countryCode" :questionnaireQuestions="questionnaireQuestions" />
        <BlacklistInformationTable v-if="getCompanyGroupSettings('useBlacklistInfoTable')" :disabled="disabled" :countryCode="countryCode" :template="template" />
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import BlacklistQuestionnaire from './BlacklistQuestionnaire';
import BlacklistInformationTable from './BlacklistInformation';

export default {
    name: 'VvtRiskBlacklistPanel',
    components: {
        BlacklistQuestionnaire,
        BlacklistInformationTable,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        countryCode: {
            type: String,
            default: null,
        },
        template: {
            type: Boolean,
            default: false,
        },
        questionnaireQuestions: {
            type: Array,
            default: () => [],
        }
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            get: 'processingActivityModel/getProperty',
            getCompanyGroupSettings: 'companyGroupSettings/get'
        }),
        canEdit () {
            return this.get('canEdit');
        },
    }
};
</script>
